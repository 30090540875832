import React from 'react'


function SloDashBoardLink({appName, env}) {


  let serviceName = appName.value.replace(/[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]+/g, '-').replace(/\s+/g, '_');
  console.log("AP NAME ", serviceName)
  return (
    <>
    {env?.value.toLowerCase() === "dev" ? 
    <a style={{textDecoration:"none", color:"rgba(0,0,0,.55)", fontSize:"18px", display:"block", marginTop:"20px"}}
 href={`https://grafana.maersk.io/d/NAmt0YhVz/slo-detail-dev?orgId=1&refresh=5m&from=now-5m&to=now&var-Datasource=Prometheus&var-Env=dev&var-service=${serviceName}&var-slo=All&var-sli_window=5m&var-min_burning_rate=1`}
target="__blank">Click here to see the Grafana Dashboard</a>

:  <a style={{textDecoration:"none", color:"rgba(0,0,0,.55)", fontSize:"18px", display:"block", marginTop:"20px"}}
 href={`https://grafana.maersk.io/d/UtYCVM0Vk/slo-detail-prod?orgId=1&refresh=5m&var-Datasource=Prometheus&var-Env=prod&var-service=${serviceName}&var-slo=All&var-sli_window=5m&var-min_burning_rate=1&from=now-5m&to=now`}
target="__blank">Click here to see the Grafana Dashboard</a>
    }
     
    </>
  
  )
}

export default SloDashBoardLink